<h2 mat-dialog-title>{{titulo}}</h2>
<div mat-dialog-content>
    <form [formGroup]="formulario">
        <mat-form-field class="example-full-width" appearance="outline" *ngIf="resultadoBusqueda && resultadoBusqueda.length > 0; else loading">
            <mat-label>Colaborador</mat-label>
            <input type="text"
                   placeholder="Buscar..."
                   aria-label="Number"
                   matInput
                   [formControlName]="'empleado'"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
              <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                {{option.nombreCompleto}}
              </mat-option>
            </mat-autocomplete>
            <mat-error>El Colaborador es obligatorio</mat-error>
          </mat-form-field>
          <ng-template #loading  class="text-center">
            <div style="display: flex; justify-content: center;">
                          <mat-spinner></mat-spinner>
            </div>
          </ng-template>

          <mat-form-field appearance="outline">
            <mat-label>Esquema de puntos</mat-label>
            <mat-select [formControlName]="'concepto'" (selectionChange)="setEvent($event)" name="concepto">
              <mat-option *ngFor="let c of conceptos" [value]="c">
                {{c.name}}
              </mat-option>
            </mat-select>
            <mat-error>El concepto es obligatorio</mat-error>

          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Actividad</mat-label>
            <mat-select [formControlName]="'actividad'" name="actividad">
              <mat-option *ngFor="let c of conceptoSelected" [value]="c">
                {{c.name}} - {{c.points}} Pts
              </mat-option>
            </mat-select>
            <mat-error>El concepto es obligatorio</mat-error>

          </mat-form-field>

          <mat-form-field class="example-full-width" appearance="outline">
            <mat-label>Justificación</mat-label>
            <textarea type="text"
                   placeholder="Agregar Justificación"
                   aria-label="Number"
                   rows="4"
                   matInput
                   [formControlName]="'justificacion'"></textarea>
                   <mat-error>La Justificación es obligatoria</mat-error>

          </mat-form-field>
    </form>
</div>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-raised-button color="primary" [mat-dialog-close]="true" (click)="guardar()" cdkFocusInitial>Agregar</button>
  </mat-dialog-actions>
