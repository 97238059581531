<app-carrito-store></app-carrito-store>

<div class="container-fluid contentall">
  <div class="row">
    <!-- SIDEBAR -->
    <div class="col-md-4 col-lg-4 sidebar p-5">

      <div class="row enlaces p-4">
        <div class="row puntos-disponibles" style="width: 100%;">
          <div class="col-lg-9 text-center pt-3">
            <span class="title text-center">{{unidadValor}} disponibles</span>
          </div>
          <div class="col-lg-3 text-center puntos pl-0 pr-0">
              <span>{{ puntosDisponibles }}</span>
          </div>
        </div>
        <div class="col-md-12">
          <div class="img-c row" href="javascript:void(0);" routerLink="/info">
            <img src="assets/img/inicio/iconos/info.SVG" class="img-fluid">
            <span class="titulo mt-1 ml-3">¿Qué es reconocimiento?</span>
          </div>
          <div class="img-c row" href="javascript:void(0);" routerLink="/mis-reconocimientos">
            <img src="assets/img/inicio/iconos/estrella.SVG" class="img-fluid">
            <span class="titulo mt-1 ml-3">Mis {{accionPlural | lowercase}}</span>
          </div>
          <div class="img-c row" href="javascript:void(0);" routerLink="/reconocer/enviar-reconocimiento">
            <img src="assets/img/inicio/iconos/aplaudir.SVG" class="img-fluid">
            <span class="titulo mt-1 ml-3">{{accionSingular}} a otros</span>
          </div>
          <div class="img-c row" href="javascript:void(0);" routerLink="/store">
            <img src="assets/img/inicio/iconos/store.SVG" class="img-fluid">
            <span class="titulo mt-1 ml-3">Tienda</span>
          </div>
        </div>
      </div>
    </div>
    <!-- ./SIDEBAR -->
    <!-- CONTENT -->
    <div class="col-md-8 col-lg-8 p-5">
      <div class="row content">
        <div class="col-md-12 text-center">
          <h1>Top 5 más Reconocidos</h1>
        </div>
        <div class="col-md-12 mb-5">
          <div class="row" *ngIf="ejecutivos.length > 0">
            <div class="col-lg-4 col-md-5 mb-5 mb-md-0">
              <div class="bg-byteAvatar mb-3 winner">
                <app-avatar-icon
                  *ngIf="ejecutivoTop1.avatar != null"
                  size="inicio"
                  type="rounded-circle"
                  bg_color="#0B3B59"
                  [image]="filespath + ejecutivoTop1.avatar"
                  text_color="white"
                  [name]="ejecutivoTop1.nombreCompleto"
                ></app-avatar-icon>
                <img *ngIf="ejecutivoTop1.avatar == null" class="img-fluid" src="assets/img/inicio/iconos/stars.SVG" style="height: 80px;">
              </div>
              <div class="nombre">{{ ejecutivoTop1.nombreCompleto }}</div>
              <div class="puesto">{{ ejecutivoTop1.puesto }}</div>
              <div class="ciudad">{{ ejecutivoTop1.area }}</div>
            </div>
            <div class="col-lg-7 col-md-7">
              <div class="list-item-award mt-4" *ngFor="let ejecutivo of ejecutivosTop2Al4">
                <div class="img-star">
<!--                  <app-avatar-icon-->
<!--                    size="xl"-->
<!--                    type="rounded-circle"-->
<!--                    bg_color="#19456E"-->
<!--                    text_color="white"-->
<!--                    [image]="ejecutivo.avatar"-->
<!--                    loading="lazy"-->
<!--                  ></app-avatar-icon>-->
                  <div *ngIf="ejecutivo.avatar != null">
                    <img class="img-fluid" [src]="filespath + ejecutivo.avatar" class="masreco">
                  </div>
                  <div *ngIf="ejecutivo.avatar == null">
                    <img class="img-fluid" src="assets/img/inicio/iconos/stars.SVG" style="height: 40px;">
                  </div>
<!--                  <img class="img-fluid" src="assets/img/inicio/iconos/stars.SVG" style="height: 40px;" *ngIf="ejecutivo.top == 2">-->
<!--                  <img class="img-fluid" src="assets/img/inicio/iconos/stars.SVG" style="height: 40px;" *ngIf="ejecutivo.top == 3">-->
<!--                  <img class="img-fluid" src="assets/img/inicio/iconos/stars.SVG" style="height: 40px;" *ngIf="ejecutivo.top != 2 && ejecutivo.top != 3">-->
                </div>
                <div class="nombre">{{ ejecutivo.nombreCompleto }}</div>
                <div class="puesto">{{ ejecutivo.puesto }}</div>
                <div class="ciudad">{{ ejecutivo.area }}</div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="ejecutivos.length <= 0">
            <div class="col-md-12">
              <div class="alert alert-secondary" role="alert">
                No hay información para mostrar.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /.CONTENT -->
  </div>
</div>

<!--<ho1a-Loading *ngIf="loading"></ho1a-Loading>-->
